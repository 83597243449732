import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {ExerciseTranslationFilter} from "../../../models/ExerciseTranslation";

export default class ExerciseTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Exercise Translations"
        this.addPath = "/admin/translations/exercises/add";
        this.editPath = "/admin/translations/exercises/edit";
        this.removeUrl = "/admin/translations/exercises";
        this.listApiUrl = "/admin/translations/exercises";
    }

    generateFilter = () => {
        return ExerciseTranslationFilter()
    }
}
