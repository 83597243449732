import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {LinksCheckerModel} from "../../models/LinkChecker"

class LinksChecker extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Links Checker"
        this.url = "/admin/linkchecker"
    }
    getEntity = () => {
        return LinksCheckerModel(
            this.context.constants,
        );
    }
    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}

export default LinksChecker;