import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {BodyPartTranslationModel} from "../../../models/BodyPartTranslation";


export default class BodyPartTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Body Part Translation"
        this.url = "/admin/translations/body-parts"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}
        return BodyPartTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null
        );
    }
}
