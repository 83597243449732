import React, {Component} from "react";
import {NavLink} from "react-router-dom";


class EditButton extends Component {
    render() {
        if (this.props.editPath) {
            return <NavLink to={this.props.editPath + '/' + this.props.id}
                            className="btn btn-primary"
            >
                Edit
            </NavLink>
        }

        return ''
    }
}

export default EditButton;
