import React, {Component} from "react";
import Button from "../CustomButton/CustomButton";


export default class DeleteButton extends Component {
    render() {
        if (this.props.removeUrl) {
            return <Button
                bsStyle="danger"
                onClick={(e) => this.props.handleRemove(this.props.id, e)}
            >
                Delete
            </Button>
        }
        return ''
    }
}